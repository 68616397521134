import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from 'react-bootstrap/ProgressBar'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AiOutlineCheckCircle,
  AiOutlineSearch
} from "react-icons/ai";
import { MdOutlineHandshake } from "react-icons/md"
import { BiCalendar } from "react-icons/bi"
import { FaChevronRight } from "react-icons/fa"
import { toast } from "react-hot-toast";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NewLineItemSaas from "./newLineItemSaas";
import Loader from "../Loader";
import Moment from "react-moment";
import moment from "moment";
import EditCampaignSaas from "./EditCampaignSaas"
import { useSelector, useDispatch } from "react-redux";
import { optimize_data_saas } from "../../app/features/optimizeTabSaas/optimizeTabSaasSlise";
import {
  ExchangeRates,
  deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";
import { FiAlertCircle } from 'react-icons/fi';
import Spinner from 'react-bootstrap/Spinner';
import {
  LineItemsSelector,
  getInventories,
  getLineitemFormats,
  getOfferTypes,
  getCountries,
  getLineItemStatus,
  linefilter,
  getCampaign,
  changeLineItemStatus,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { get_pacing } from "../../app/features/update_optimize_tab/get_pacing";
import { get_pacing_rate } from "../../app/features/update_optimize_tab/get_pacing_rate_slice";
import { clear_update_pacing_rate_state } from "../../app/features/update_optimize_tab/update_pacing_rate_slice";
import { clear_update_pacing_state } from "../../app/features/update_optimize_tab/update_pacing_slice";
import { update_optimize_tab_saas } from "../../app/features/optimizeTabSaas/update_optimize_tab_slice";
import { optimize_tab_saas_report_data } from "../../app/features/optimizeTabSaas/optimize_saas_report_data_slice";
import { kite_states } from '../../app/features/kiteStates/kite_states_slice';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

// import Moment from 'react-moment';
const LineItemsSaas = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [showEditLineitem, setShowEditLineitem] = useState(false);
  const [campaign_update_status, setCampaign_update_status] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCampaign = () => setShowCampaign(false);
  const handleShowCampaign = () => setShowCampaign(true);
  const handleCloseCampaignStatusUpdate = () => setCampaign_update_status(false);
  const handleShowCampaignStatusupdate = () => setCampaign_update_status(true);
  const handleCloseEditLineitem = () => setShowEditLineitem(false);
  const handleShowLineitem = () => setShowEditLineitem(true);
  const navigate = useNavigate()
  const location = useLocation()
  const [loadershown, setloadershown] = useState(false);
  const [all_lineitems, setAll_lineitems] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total_pages, setTotal_Pages] = useState(null)
  const [campaign_details, setCampaign_details] = useState([])
  const [campaign_name, setCampaign_name] = useState("")
  const [campaign_start_date, setCampaign_start_date] = useState("")
  const [campaign_end_date, setCampaign_end_date] = useState("")
  const [campaign_current_start_date, setCampaign_current_start_date] = useState("")
  const [campaign_current_end_date, setCampaign_current_end_date] = useState("")
  const [campaign_budget, setCampaign_budget] = useState(null)
  const [lineitem_id, setLineitem_id] = useState(null)
  const [campaign_budget_usd, setCampaign_budget_usd] = useState(null)
  const { dealCurrencies } = useSelector(deligateSelector);
  const [active_button, setActive_button] = useState(false)
  const [showbudget, setShowbudget] = useState(false);
  const [lineitem_change_id, setLineitem_change_id] = useState(null)
  const [lineitem_status_id, setLineitem_status_id] = useState(null)
  const [searched_value, setSearched_value] = useState("")
  const [lineitem_name_list, setLineitem_name_list] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const [lineitem_searched_id, setLineitem_searched_id] = useState(null)
  const [lineitem_searched_name, setLineitem_searched_name] = useState("")
  const [lineitem_searched_inventory, setLineitem_searched_inventory] = useState(null)
  const [lineitem_searched_offer, setLineitem_searched_offer] = useState(null)
  const [lineitem_searched_format, setLineitem_searched_format] = useState(null)
  const [lineitem_searched_country, setLineitem_searched_country] = useState(null)
  const [inventoryId, setInventoryId] = useState()
  const [formatId, setFormatId] = useState()
  const [offerId, setOfferId] = useState()
  const [countryId, setCountryId] = useState()
  const [pdfTrue, setPdfTrue] = useState(false)
  const [tempInventory, setTempInventory] = useState("Inventory")
  const [tempFormat, setTempFormat] = useState("Format")
  const [tempOffer, setTempOffer] = useState("Offer")
  const [tempCountry, setTempCountry] = useState("Country")
  const [all_user_list, setAll_user_list] = useState([])
  const [owner_email_id, setOwner_email_id] = useState(null)
  const [owner_email, setOwner_email] = useState("Select Owner")
  const [statusText, setStatusText] = useState("")
  const [sort, setSort] = useState(false)
  const [update_bulk, setUpdate_bulk] = useState(false)
  const [lineitem_status, setLineitem_status] = useState(null)
  const [lineitemIDS, setLineitemIDS] = useState([])
  const [reportData, setReportData] = useState([])
  const [selected_budget, setSelected_budget] = useState(0)
  const [selected_bid, setSelected_bid] = useState(0)
  const [validate_apply_button, setValidate_apply_button] = useState(false)
  const [states, setStates] = useState([])
  const [total_records, setTotal_records] = useState(null)
  const [hide_filter, setHide_filter] = useState(true)

  const handleClosebudget = () => setShowbudget(false);
  const handleShowbudget = () => setShowbudget(true);
  const [lineItemStatus, setLineItemStatus] = useState(false);

  const handleCloseCampaignStatus = () => setLineItemStatus(false);
  const handlelineItemStatus = () => setLineItemStatus(true);

  const [lineConfirmStatus, setLineConfirmStatus] = useState(false);
  const handleCloseConfirmStatus = () => setLineConfirmStatus(false);
  const handlelineConfirmStatus = () => setLineConfirmStatus(true);
  //this below variable is used to get the advertiser_id from the localstorage and we used this in get all clients endpoint and for all permissions nitin 20/09/2023
  const permission = JSON.parse(localStorage.getItem('userdata'));

  const optimize_saas_data = useSelector((state) => { return state.optimize_saas })
  const update_optimize_tab = useSelector((state) => { return state.update_optimize_saas })
  const [mapData, setMapData] = useState([])
  const optimize_saas_report_data = useSelector((state) => { return state.optimizeTab_saas_report_data })

  useEffect(() => {
    if (optimize_saas_report_data?.isSuccess) {
      var data = Object.values(optimize_saas_report_data?.data?.data)
    }
    setReportData(data)
  }, [optimize_saas_report_data])

  useEffect(() => {
    let lineitem_ids = []
    optimize_saas_data?.data?.data?.map((ids, i) => {
      lineitem_ids.push(ids?.id)
    })
    setLineitemIDS(lineitem_ids)
  }, [optimize_saas_data])

  useEffect(() => {
    if (lineitemIDS?.length !== 0) {
      dispatch(optimize_tab_saas_report_data({ lineitemIDS }))
    }
  }, [lineitemIDS])

  useEffect(() => {
    dispatch(kite_states({ CountryId: [4, 12, 21] }))
  }, [])

  const all_states = useSelector((state) => { return state.kitestates })

  useEffect(() => {
    if (all_states?.isSuccess) {
      setStates(all_states?.data?.data[0])
    }
  }, [all_states])

  useEffect(() => {
    setMapData(optimize_saas_data?.data?.data)
  }, [optimize_saas_data])

  useEffect(() => {
    if (mapData?.length > 0) {
      let updatedMapData = [...mapData]; // Create a copy of mapData
      mapData?.forEach((data, i) => {
        let country_id = [];
        if (!data?.countryTrue) {
          data?.countryList?.forEach((count) => {
            country_id?.push(count?.id);
          });
          if (JSON.stringify(updatedMapData[i]?.countriesData) !== JSON.stringify(country_id)) {
            updatedMapData[i] = { ...updatedMapData[i], countriesData: country_id };
          }
        }
      });
      if (JSON.stringify(updatedMapData) !== JSON.stringify(mapData)) {
        setMapData(updatedMapData);
      }
    }
  }, [mapData, optimize_saas_data]);


  useEffect(() => {
    if (mapData?.length > 0) {
      let updatedMapData = [...mapData]; // Create a copy of mapData
      mapData?.forEach((data, i) => {
        let state_ids = [];
        if (!data?.stateTrue) {
          data?.stateList?.forEach((state) => {
            state_ids?.push(state?.id);
          });
          if (JSON.stringify(updatedMapData[i]?.statesData) !== JSON.stringify(state_ids)) {
            updatedMapData[i] = { ...updatedMapData[i], statesData: state_ids };
          }
        }
      });
      if (JSON.stringify(updatedMapData) !== JSON.stringify(mapData)) {
        setMapData(updatedMapData);
      }
    }
  }, [mapData, optimize_saas_data]);

  useEffect(() => {
    if (optimize_saas_data?.isSuccess) {
      setloadershown(true)
    }
    else {
      setloadershown(false)
    }
  }, [optimize_saas_data])

  useEffect(() => {
    if (update_optimize_tab?.isSuccess) {
      dispatch(optimize_data_saas({ id: campaign_id }))
      setUpdate_bulk(false)
      get_all_lineitems()
      get_selected_campaign_detail()
    }
  }, [update_optimize_tab])




  const {
    countries,
  } = useSelector(LineItemsSelector);

  const {
    //here in this path we get the client id from the prvious page and here we can hit a endpoint to get all the information of selected campaign nitin 05/09/2023
    campaign_id, client_id, status_id, deal_currency, deal_status_id, advertiser_id, currency, campaign_status, client_deal_id, dealId, valueUndefined, lineitem_searched_id_value,
    lineitem_searched_name_value,
    lineitem_searched_inventory_value,
    lineitem_searched_format_value,
    lineitem_searched_offer_value,
    lineitem_searched_country_value,
  } = location.state ? location.state : location;

  useEffect(() => {
    dispatch(optimize_data_saas({ id: campaign_id }))
  }, [campaign_id])

  // this below function is used to get all the deals values nitin 19/09/2023
  useEffect(() => {
    if (deal_currency != "") {
      dispatch(ExchangeRates({ dealCurrency: deal_currency }));
    }
  }, [deal_currency]);

  const updated_pacing_data = useSelector((state) => { return state.update_pacing_value })
  const update_pacing_rate_data = useSelector((state) => { return state.update_pacing_rate_value })

  useEffect(() => {
    if (updated_pacing_data?.isSuccess) {
      dispatch(optimize_data_saas({ id: campaign_id }))
      dispatch(clear_update_pacing_state())
    }
  }, [updated_pacing_data])

  useEffect(() => {
    if (update_pacing_rate_data?.isSuccess) {
      dispatch(optimize_data_saas({ id: campaign_id }))
      dispatch(clear_update_pacing_rate_state())
    }
  }, [update_pacing_rate_data])

  // this below function is used to get all the country offers inventories,formats nitin 21/09/2023
  useEffect(() => {

    dispatch(getInventories({ token: localStorage.getItem("token") }))
      .then((result) => {
        setInventoryId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getLineitemFormats({ token: localStorage.getItem("token") }))
      .then((result) => {
        setFormatId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getOfferTypes({ token: localStorage.getItem("token") }))
      .then((result) => {
        setOfferId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getCountries({ token: localStorage.getItem("token") }))
      .then((result) => {
        setCountryId(result?.payload?.data)
      })
      .catch((err) => { });
  }, []);

  //this below function is used to get the details of selected campaign based on client_id and campaign_id nitin 06/09/2023
  useEffect(() => {
    if (client_id && campaign_id) {
      get_selected_campaign_detail()
    }
  }, [campaign_id, client_id])

  const get_selected_campaign_detail = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/getCampaignAdvertiserClient/${client_id}/${campaign_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setCampaign_details(result?.data);
        setCampaign_name(result?.data?.name);
        setCampaign_budget(result?.data?.budge);
        setCampaign_budget_usd(result?.data?.budget_usd);
        setCampaign_start_date(new Date(result?.data?.date_from));
        setCampaign_end_date(new Date(result?.data?.date_to))
        setCampaign_current_start_date(new Date(result?.data?.date_from));
        setCampaign_current_end_date(new Date(result?.data?.date_to))
      })


  }

  //this below function is used to get all the lineitems based on campaign id nitin 05/09/2023
  useEffect(() => {
    if (valueUndefined == undefined) {
      get_all_lineitems();
    }
  }, [])

  useEffect(() => {
    if (page !== 1 || pageSize !== 10) {
      get_all_lineitems();
    }
  }, [page, pageSize])

  useEffect(() => {
    optimize_saas_data?.loading ? setloadershown(true) : setloadershown(false)
  }, [optimize_saas_data])

  const get_all_lineitems = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/lineItems/${campaign_id}/?page=${page}&pageSize=${pageSize}`, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_lineitems(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count); setTotal_records(result?.data?.row_count); !hide_filter && setPdfTrue(true) })
      .catch(error => { toast.error("Internal Error Occurred !!"); setloadershown(false) });
  }

  //this below variable is calculating the percentage on the basis of the budget of campaign
  const percentage = (campaign_details?.lineItemTotalBudget / campaign_details?.budge) * 100

  //this function is used to go to the creatives page we also send the lineitem id that we are clicking on nitin 06/09/2023
  const go_to_next = (id) => {
    navigate("/campaignsaas/Creativessaas", {
      state: {
        lineitem_id: id,
        dealID: campaign_details?.dealID,
        campaign_id: campaign_id,
        client_id: client_id,
        deal_currency: deal_currency,
        campaign_budget: campaign_details?.budge,
        remaining_budget: (campaign_details?.budge) - (campaign_details?.lineItemTotalBudget),
        deal_status_id: deal_status_id,
        advertiser_id: advertiser_id,
        currency: currency,
        Budget_usd: campaign_details?.budget_usd,
        lineitem_status: lineitem_status,
        deal_currency: deal_currency,
        lineitem_searched_id_value,
        lineitem_searched_name_value,
        lineitem_searched_inventory_value,
        lineitem_searched_format_value,
        lineitem_searched_offer_value,
        lineitem_searched_country_value
      }
    })
  }

  //this below endpoint is used to update the status of lineitem nitin 08/09/2023
  const handle_update_lineitem_status = () => {
    handleCloseCampaignStatus()
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    var raw = JSON.stringify({
      "id": lineitem_change_id,
      "status_id": lineitem_status_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/update-lineItem-status`, requestOptions)
      .then(response => response.json())
      .then(result => { get_all_lineitems(); dispatch(optimize_data_saas({ id: campaign_id })); toast.success("Lineitem Status Updated Successfully"); if (lineitem_status_id === 2) { handleShowCampaignStatusupdate(); } })
      .catch(error => { toast.error("Internal Server Error Occurred While Updating lineitem Status"); setloadershown(false) });
  }

  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  }
  const isCampaignEndDateError = () => {
    let isError = false
    all_lineitems?.forEach(lineItem => {
      if (normalizeDate(new Date(lineItem.date_to)) > normalizeDate(new Date(campaign_end_date))) {
        isError = true;
      }
    });
    return isError
  }

  //this below function is used to update the selected campaign nitin 08/09/2023
  const handle_update_campaigns = () => {
    if (campaign_name === "") {
      toast.error("Campaign name can't be empty")
    }
    else if (campaign_start_date === "" || campaign_end_date === "") {
      toast.error("Please select dates")
    }
    else if (campaign_budget === null) {
      toast.error("Please add budget")
    }
    else if (campaign_budget < campaign_details?.lineItemTotalBudget) {
      toast.error(`Budget cannot be lower than ${campaign_details?.lineItemTotalBudget}`)
    }
    else if (isCampaignEndDateError()) {
      toast.error('Campaign end date must be after all line items end date')
    }
    else {
      setloadershown(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "id": campaign_id,
        "name": campaign_name,
        "kite_deal_id": client_deal_id ? client_deal_id : campaign_details?.kite_deal_id,
        "date_from": campaign_start_date,
        "date_to": campaign_end_date,
        "budge": campaign_budget,
        "kite_advertiser_client_id": client_id,
        "status_id": status_id,
        "budget_usd": campaign_budget_usd,
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/updateCampaignAdvertiserClient`, requestOptions)
        .then(response => response.text())
        .then(result => { get_all_lineitems(); get_selected_campaign_detail(); toast.success("Campaign updated succesfully"); handleCloseCampaign() })
        .catch(error => { toast.error("Internal Error Occurred While Updating the Campaign"); setloadershown(false) });
    }
  }

  const handle_edit_lineitem = (id) => {
    setLineitem_id(id)
    handleShowLineitem()
  }

  // this below function is used to enable the save button nitin 19/09/2023
  useEffect(() => {
    if (campaign_name !== "" && campaign_start_date !== "" && campaign_end_date !== "" && campaign_budget !== "") {
      setActive_button(true)
    } else if (campaign_name === "" || campaign_start_date === "" || campaign_end_date === "" || campaign_budget === "") {
      setActive_button(false)
    }
  }, [campaign_name, campaign_start_date, campaign_end_date, campaign_budget])

  const handle_show_status_pop = (lineitem_id, status_id, lineitem_status) => {
    if (status_id == 2) {
      setStatusText(" Are you sure you want to Active this Lineitem?");
    }
    if (status_id == 28) {
      setStatusText("  Are you sure you want to put as Inactive this Lineitem?");
    }
    if (status_id == 1) {
      setStatusText(" Are you sure you want to Draft this Lineitem?");
    }
    if (status_id == 3) {
      setStatusText("Are you sure you want to Pause this Lineitem?");
    }
    if (lineitem_status == 1 && status_id == 1) {
      toast.error("You cannot change the status to draft again")
    }
    else if (lineitem_status == 1 && (status_id == 3 || status_id == 4)) {
      toast.error("You can only change the status to Active")
    }
    else if (lineitem_status == 2 && (status_id == 1 || status_id == 2)) {
      toast.error("You can only change the status of campaign Pause and Inactive")
    }
    else if (lineitem_status == 3 && (status_id == 1 || status_id == 3)) {
      toast.error("You can only change the status of campaign Active and Inactive")
    } else {
      handlelineItemStatus();
      setLineitem_change_id(lineitem_id)
      setLineitem_status_id(status_id)
    }
  }

  const handle_search_with_name = (e) => {
    setSpinner(true)
    setShow_div(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaign_id": campaign_id,
      "name": e.target.value,
      "id": !isNaN(e.target.value) && e.target.value !== '' ? e.target.value : 0
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/filter-lineitem`, requestOptions)
      .then(response => response.json())
      .then(result => { setLineitem_name_list(result?.data); setSpinner(false) })
  }

  const handle_searched_lineitem = (searchedName, searchedLineItemId) => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/lineItems/${campaign_id}/?page=${page}&pageSize=${pageSize}`;


    if (lineitem_searched_inventory) {
      url += `&inventoryId=${lineitem_searched_inventory}`;
    }
    if (lineitem_searched_format) {
      url += `&formatId=${lineitem_searched_format}`;
    }
    if (lineitem_searched_offer) {
      url += `&offerTypeId=${lineitem_searched_offer}`;
    }
    if (lineitem_searched_country) {
      url += `&countryId=${lineitem_searched_country}`;
    }
    if (searchedLineItemId) {
      url += `&Lineitemid=${searchedLineItemId}`;
    }
    if (searchedName) {
      url += `&name=${searchedName}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_lineitems(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })
  }

  useEffect(() => {
    if (lineitem_searched_inventory_value != undefined || lineitem_searched_format_value != undefined || lineitem_searched_offer_value != undefined || lineitem_searched_country_value != undefined || lineitem_searched_id_value != undefined || lineitem_searched_name_value != undefined) {
      handle_previous_searched_lineitem()
    }

  }, [lineitem_searched_inventory_value, lineitem_searched_format_value, lineitem_searched_offer_value, lineitem_searched_country_value, lineitem_searched_id_value, lineitem_searched_name_value])

  const handle_previous_searched_lineitem = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/lineItems/${campaign_id}/?page=${page}&pageSize=${pageSize}`;


    if (lineitem_searched_inventory_value) {
      url += `&inventoryId=${lineitem_searched_inventory_value}`;
    }
    if (lineitem_searched_format_value) {
      url += `&formatId=${lineitem_searched_format_value}`;
    }
    if (lineitem_searched_offer_value) {
      url += `&offerTypeId=${lineitem_searched_offer_value}`;
    }
    if (lineitem_searched_country_value) {
      url += `&countryId=${lineitem_searched_country_value}`;
    }
    if (lineitem_searched_id_value) {
      url += `&Lineitemid=${lineitem_searched_id_value}`;
    }
    if (lineitem_searched_name_value) {
      url += `&name=${lineitem_searched_name_value}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_lineitems(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })
  }

  const get_filtered_optimize_data = (searchedName, searchedLineItemId) => {
    setloadershown(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/lineitemoptimization?campaignId=${campaign_id}`;
    if (lineitem_searched_inventory) {
      url += `&inventoryId=${lineitem_searched_inventory}`;
    }
    if (lineitem_searched_format) {
      url += `&formatId=${lineitem_searched_format}`;
    }
    if (lineitem_searched_offer) {
      url += `&offerTypeId=${lineitem_searched_offer}`;
    }
    if (lineitem_searched_country) {
      url += `&countryId=${lineitem_searched_country}`;
    }
    if (searchedLineItemId) {
      url += `&Lineitemid=${searchedLineItemId}`;
    }
    if (searchedName) {
      url += `&name=${searchedName}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setMapData(result?.data);
        setloadershown(false)

      })
      .catch(error => { toast.error('internal server error occurred'); setloadershown(false) });

  }

  useEffect(() => {
    get_all_users()
  }, [])

  const get_all_users = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'))
    var raw = JSON.stringify({
      "advertiser_id": advertiser_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getuserEmail`, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_user_list(result?.data) })
  }

  useEffect(() => {
    if (owner_email_id !== null) {
      handle_assign_user()
    }
  }, [owner_email_id])

  const handle_assign_user = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaignid": campaign_id,
      "userid": owner_email_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/saveuserEmail`, requestOptions)
      .then(response => response.text())
      .then(result => { toast.success("Owner assigned successfully") })
  }


  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": campaign_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/reflectedUseremail`, requestOptions)
      .then(response => response.json())
      .then(result => setOwner_email(result?.data[0]?.email))
  }, [])

  // this below function is used to update the status of campaign when we turn on lineitem to active nitin 29/09.2023
  const handle_update_campaign_status = () => {
    setloadershown(true)
    handleCloseCampaignStatusUpdate()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": lineitem_change_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/LineItemStatus`, requestOptions)
      .then(response => response.json())
      .then(result => { toast.success("Campaign status Active Successfully"); get_all_lineitems(); get_selected_campaign_detail() })
  }

  // this below function is used to handle the data sorting based on the field name nitin 11/10/2023
  const handle_sort = (field_name) => {
    setSort(!sort)
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/lineItems/${campaign_id}/?page=${page}&FieldName=${field_name}&Order=${sort ? "desc" : "asc"}&pageSize=${pageSize}`;

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => { setAll_lineitems(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })

  }

  // this function is used to delete the selected campaign nitin 20/11/2023
  const handleDeleteCampaign = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/deleteCampaignAdvertiserClient/${campaign_id}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        toast.success("Campaign Deleted successfully!"); navigate('/campaignsaas/mediaplansaas', {
          state: {
            client_id: client_id,
            deal_status_id: deal_status_id,
            advertiser_id: advertiser_id,
            currency: currency,
            deal_currency: deal_currency
          }
        })
      })
  }

  const handle_get_lineitem_bid_usd_budget_usd = () => {
    alert(dealId)
    if (campaign_budget) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "deal_id": dealId,
        "campaign_id": campaign_id,
        "inversion_budget": campaign_budget,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/calculatebudgetusd`, requestOptions)
        .then(response => response.json())
        .then(result => { setCampaign_budget_usd(result?.data?.inversion_goal_usd) })

    }
  }
  // here on below variables we are storing all pacings
  const pacing = useSelector((state) => { return state.all_pacing })

  // this api is get all the pacings for optimize tab nitin
  useEffect(() => {
    dispatch(get_pacing())
  }, [])

  // here on below variables we are storing all pacings rates
  const pacing_rate = useSelector((state) => { return state.all_pacing_rate })

  // this api is get all the pacings rates for optimize tab nitin
  useEffect(() => {
    dispatch(get_pacing_rate())
  }, [])

  var editable_data = []

  const handleEditData = (data, i) => {
    setUpdate_bulk(true);
    editable_data = mapData?.map((item, index) => {
      if (index === i && data === "name") {
        return { ...item, nameTrue: true };
      }
      if (index === i && data === "inversionBudget") {
        return { ...item, budgetTrue: true };
      }
      if (index === i && data === "inversionSellRate") {
        return { ...item, sellRateTrue: true };
      }
      if (index === i && data === "marginSetup") {
        return { ...item, marginTrue: true };
      }
      if (index === i && data === "marginSetup") {
        return { ...item, marginTrue: true };
      }
      if (index === i && data === "startDate") {
        return { ...item, startDateTrue: true };
      }
      if (index === i && data === "endDate") {
        return { ...item, endDateTrue: true };
      }
      if (index === i && data === "countryTrue") {
        return { ...item, countryTrue: true };
      }
      if (index === i && data === "stateTrue") {
        return { ...item, stateTrue: true };
      }
      if (index === i && data === "budget") {
        return { ...item, budgetTrue: true };
      }
      if (index === i && data === "bid") {
        return { ...item, bidTrue: true };
      }
      return item;
    });
    setMapData(editable_data)
  };

  const handleChangeValues = (i, name, e, id) => {
    if (!(name === "bid" && (e.target.value == " " || e.target.value == ""))) {
      setUpdate_bulk(true);
      const updatedData = mapData.map((item, index) => {
        if (index === i) {
          if (name === "name") {
            return { ...item, name: e.target.value, isModified: true };
          }
          if (name === "budget") {
            const spentToDate = reportData
              .filter(itemSpentFilter => itemSpentFilter.lineitemId === item.id)
              .map(itemSpentMap => itemSpentMap.spent);
            if (e.target.value < spentToDate[0]) {
              setValidate_apply_button(true)
              toast.error(`Budget can't be lower than ${spentToDate[0].toLocaleString()}`)
            }
            else {
              const total_budget = parseInt(campaign_details?.budge) - parseInt(campaign_details?.lineItemTotalBudget)
              if (e.target.value > (total_budget + selected_budget)) {
                setValidate_apply_button(true)
                toast.error(`Budget can't be greater then ${total_budget + selected_budget}`)
              } else if (!e.target.value == "") {
                setValidate_apply_button(false)
                return { ...item, inversionBudget: e.target.value, isModified: true };
              }
            }
          }
          if (name === "inversionSellRate") {
            return { ...item, inversionSellRate: e.target.value, isModified: true };
          }
          if (name === "marginSetup") {
            return { ...item, marginSetup: e.target.value, isModified: true };
          }
          if (name === "inventoryTypeName") {
            return { ...item, inventoryTypeName: e, inventoryType: id, isModified: true };
          }
          if (name === "formatName") {
            return { ...item, formatName: e, format: id, isModified: true };
          }
          if (name === "offerTypeName") {
            return { ...item, offerTypeName: e, offerType: id, isModified: true };
          }
          if (name === "pacingName") {
            return { ...item, pacingName: e, pacing: id, isModified: true };
          }
          if (name === "pacingRateName") {
            return { ...item, pacingRateName: e, pacingRate: id, isModified: true };
          }
          if (name === "lineItemStatusName") {
            return { ...item, lineItemStatusName: e, lineItemStatus: id, isModified: true };
          }
          if (name === "budget") {
            return { ...item, inversionBudget: e.target.value, isModified: true };
          }
          if (name === "bid") {
            if (e.target.value < 0.02) {
              setValidate_apply_button(true);
              toast.error("Bid USD cannot be less than 0.02 USD")
            }
            else if (e.target.value <= 0) {
              setValidate_apply_button(true);
              toast.error("Bid USD must be higher than 0")
            }
            else if (e.target.value === "") {
              setValidate_apply_button(true);
              toast.error("Bid must have values")
            }
            else {
              setValidate_apply_button(false);
              return { ...item, bid: e.target.value, isModified: true };
            }
          }
        }
        return item;
      });
      setMapData(updatedData);
    }
  };

  let current = new Date();
  let cDate = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear();

  let hours = current.getHours();
  let minutes = current.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in PM

  let cTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  let dateTime = cDate + ' ' + cTime;

  const export_pdf = () => {
    setloadershown(true);

    html2canvas(document.getElementsByClassName('content')[0], {
      scale: 2, // Adjust scale as needed
      scrollX: 0,
      scrollY: 0,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(pdfWidth / canvas.width, pdfHeight / canvas.height);
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save("Lineitem_Saas.pdf");
      setPdfTrue(false);
      setloadershown(false);
      setPageSize(10)
      setHide_filter(true)
    }).catch((error) => {
      setloadershown(false);
    });
  };

  useEffect(() => {
    if (pdfTrue) {
      export_pdf()
    }
  }, [pdfTrue])

  const demoData = () => {
    let elements = document.getElementsByClassName('select_position');
    let elementArray = Array.from(elements); // Convert HTMLCollection to array
    if (elementArray.length > 0) {
      elementArray.forEach((element) => {
        let rect = element.getBoundingClientRect();
        let siblingElement = element?.lastElementChild;
        siblingElement.style.left = rect.left + 'px';
      });
    }
  };
  // 
  const handleClickOutside = (event) => {
    let elements = document.getElementsByClassName('select_position');
    let elementArray = Array.from(elements); // Convert HTMLCollection to array
    let isOutside = true;
    elementArray.forEach((element) => {
      if (element.contains(event.target)) {
        isOutside = false;
      }
      if (isOutside) {
        let siblingElement = element?.lastElementChild;
        siblingElement.style.removeProperty('left');
      }
    });
  };

  useLayoutEffect(() => {
    demoData();
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handle_validate_budhet = (i) => {
    let budge = 0
    optimize_saas_data?.data?.data?.map((data, index) => {
      if (i == index) {
        budge = data?.inversionBudget
      }
    })
    setSelected_budget(budge)
  }
  const handle_validate_bid = (i) => {
    let budge = 0
    optimize_saas_data?.data?.data?.map((data, index) => {
      if (i == index) {
        budge = data?.bid
      }
    })
    setSelected_bid(budge)
  }

  const handleUpdateOptimizeTab = () => {
    let validate_budget = 0
    let errorFound = false

    mapData?.forEach((val, i) => {
      if (val?.inversionBudget <= 0) {
        toast.error(`Budget must be higher than 0`)
        errorFound = true
      } else {
        validate_budget += parseInt(val?.inversionBudget)
      }
    })

    if (!errorFound) {
      dispatch(update_optimize_tab_saas({ updated_items: mapData }))
    }
  }

  return (
    <div className="content_outer position-unset">
      <Loader showLoader={loadershown || optimize_saas_data?.loading || update_optimize_tab?.loading} />
      <div className="cmn_header_top">
        <h3>
          <img src="/assets/images/chevron_left.svg" onClick={() => navigate('/campaignsaas/mediaplansaas', {
            state: {
              client_id: client_id,
              deal_status_id: deal_status_id,
              advertiser_id: advertiser_id,
              currency: currency,
              deal_currency: deal_currency
            }
          })} />
          Campaign Manager Saas
        </h3>
        <div className="cmn_header__search search_bar position-relative">
          <AiOutlineSearch size={24} className={searched_value.length > 0 ? "changeIcon" : ""} />
          <input
            type="text"
            placeholder="Search by Id or Lineitem Name"
            value={searched_value}
            onChange={(e) => {
              handle_search_with_name(e);
              setSearched_value(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (isNaN(searched_value)) {
                  handle_searched_lineitem(searched_value, null);
                  get_filtered_optimize_data(searched_value, null);
                } else {
                  handle_searched_lineitem(null, searched_value);
                  get_filtered_optimize_data(null, searched_value);
                }
                setShow_div(false);
              }
            }}
            onClick={() => {
              setShow_div(false); setLineitem_name_list([])
            }}
          />
          {show_div && <div className="search_dropown ">
            {spinner &&
              <div className="p-3 text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }
            <ul>
              {
                lineitem_name_list?.map((data, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        handle_searched_lineitem(data?.name, null);
                        get_filtered_optimize_data(data?.name, null);
                        setShow_div(false);
                        setSearched_value(data?.name)
                      }}>
                      {`${data?.name} (${data.id})`}
                    </li>
                  )
                })
              }
            </ul>
          </div>}
        </div>
        <div className="bill_btn" title="Print Pdf" onClick={() => { setHide_filter(false); setPageSize(total_records); setPage(1) }}>
          <button className="cmn_btn cmn_bordered_btn">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="24" width="24" className="me-2" xmlns="http://www.w3.org/2000/svg"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z"></path></svg>
            Print Pdf
          </button>
        </div>
        {(deal_status_id == 1 || deal_status_id == 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && <div className="">
          {(campaign_details?.budge - campaign_details?.lineItemTotalBudget > 0) && <button onClick={handleShow}
            className="cmn_btn ms-0"
          >
            <FontAwesomeIcon className="ms-2" icon={faPlus} />
            Add New Line Item
          </button>}
          {campaign_details?.budge - campaign_details?.lineItemTotalBudget <= 0 && <button onClick={handleShowbudget}
            className="cmn_btn ms-0"
          >
            <FontAwesomeIcon className="ms-2" icon={faPlus} />
            Add New Line Item
          </button>}
        </div>}

      </div>

      <Modal
        show={lineItemStatus}
        onHide={handleCloseCampaignStatus}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5"> {statusText}
            </h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={handleCloseCampaignStatus}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "
                onClick={() => {
                  handle_update_lineitem_status()
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Conformation madal */}

      <Modal
        show={lineConfirmStatus}
        onHide={handleCloseConfirmStatus}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5"> Are You sure you want to Confirm
            </h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={handleCloseConfirmStatus}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "

              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Conformation madal */}



      {/* this modal is for update the status of campaign and creative when update the status of lineitem to active */}
      <Modal
        show={campaign_update_status}
        onHide={handleCloseCampaignStatusUpdate}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5">Are you want to Active Campaign too ?
            </h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={handleCloseCampaignStatusUpdate}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "
                onClick={() => {
                  handle_update_campaign_status()
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showbudget}
        onHide={handleClosebudget}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="p-4">
          <h5 className="text-center mb-0">
            Because there is no extra budget decrease the budget from Line-item
            and return .
          </h5>
          <div className="d-flex justify-content-center mt-4">
            <button className="m-auto cancel_cm_btn " onClick={handleClosebudget}>Cancel</button></div>
        </Modal.Body>
      </Modal>
      <div className="content pt-0">
        <div className="App">
          <div className="mediaplan_details kite_space_cmn">
            {pdfTrue ? <div className=" p-3 pdf_head mb-5">
              <div className="d-flex justify-content-between align-items-center mb-5">
                <img src="/assets/images/kidtech.svg" width="150" />
                <img src="/assets/images/mariano.png" width="150" />
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h2 style={{ fontSize: "32px", fontWeight: "bold" }} className="mb-0 header_title">{campaign_details?.advertiserName}</h2>
                <h2 style={{ fontSize: "32px" }} className="mb-0 header_title">{dateTime}</h2>
              </div>
            </div> : null}
            <div className="title__active">
              <h3>{campaign_details?.name}</h3>
              <div className="status_badge">
                {campaign_details?.status_id && <p>
                  {campaign_details?.status_id == 1 && <img src="/assets/images/draft_FILL.svg" />}
                  {campaign_details?.status_id == 1 && <span className="text-secondary">  Draft</span>}
                  {campaign_details?.status_id == 2 && <img src="/assets/images/check_circle.svg" />}
                  {campaign_details?.status_id == 2 && <span className="text-secondary">  Active</span>}
                  {campaign_details?.status_id == 3 && <img src="/assets/images/pause_circle.svg" />}
                  {campaign_details?.status_id == 3 && <span className="text-secondary">  Pause</span>}
                  {campaign_details?.status_id == 4 && <img src="/assets/images/cancel_FILL.svg" />}
                  {campaign_details?.status_id == 4 && <span className="text-secondary">  Inactive</span>}
                </p>}
              </div>
            </div>
            <Row>
              <Col lg={8}>
                <div className="advert_tabel">
                  <div className="media_plan_info_list">
                    <ul className="header__media">
                      <li>
                        <MdOutlineHandshake size={24} />
                        <strong>Campaign Id</strong>
                        <p>{campaign_id}</p>
                      </li>
                      <li>
                        <BiCalendar size={20} />
                        <strong>Start Date</strong>
                        <p>
                          {<Moment format="DD/MM/YYYY">
                            {campaign_details?.date_from}
                          </Moment>}
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>End Date</strong>
                        <p>
                          {<Moment format="DD/MM/YYYY">
                            {campaign_details?.date_to}
                          </Moment>}
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>Flight days</strong>
                        {<p>{moment(campaign_details?.date_to).diff(
                          moment(campaign_details?.date_from),
                          "days"
                        )}</p>}
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>Remaining days</strong>
                        <p>{Date.now() > moment(campaign_details?.date_from) ? moment(campaign_details?.date_to).diff(
                          moment(),
                          "days"
                        ) : moment(campaign_details?.date_to).diff(
                          moment(campaign_details?.date_from),
                          "days"
                        )}</p>
                      </li>
                    </ul>
                    <ul className="header__media pt-0">
                      <li className="flex-grow-0 d-flex select_email_dropdwon">
                        <strong>Owner</strong>
                        <Dropdown className="owner_filter owners_drop_down flex-grow-1">
                          {/* first */}
                          <Dropdown.Toggle id="dropdown-basic" className="w-100">
                            {owner_email}
                            <svg className="ms-auto" width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                              <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {all_user_list?.map((data, index) => {
                              return (
                                <Dropdown.Item key={index} onClick={() => { setOwner_email(data?.email); setOwner_email_id(data?.userid) }}>{data?.email}</Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      {(deal_status_id == 1 || deal_status_id == 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && <li className="ms-auto" title="Edit Campaign" style={{ cursor: "pointer" }} onClick={handleShowCampaign}><div className="pdf_icon"><img src="/assets/images/edit_fill.svg" /></div></li>}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} >
                <div>
                  <ul className="budget single_budget line_item_buget">
                    <li className="d-flex w-100 align-items-center">
                      Budget({deal_currency})
                      {/* <p className="ms-auto mb-0">Total: <strong>{campaign_details?.budge}</strong></p> */}
                    </li>
                    <li className="progress_result">
                      <div className="w-100">
                        <ProgressBar className="bar_color w-100" now={percentage} />
                      </div>
                    </li>
                    <li className="total_used">
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Used : <strong>{campaign_details?.lineItemTotalBudget ? (campaign_details?.lineItemTotalBudget)?.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        ) : 0}</strong></p>
                      </div>
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Remaining : <strong>{((campaign_details?.budge) - (campaign_details?.lineItemTotalBudget))?.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2 }
                        )}</strong></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="">
          <div className="new_compaign">
            {/* tabs starts here */}
            <div className="mediaplan__tabs single_tab">
              <div className=" border-bottom-0 mb-0">
                <Tabs
                  defaultActiveKey="setup"
                  id="uncontrolled-tab-example"
                  className="tabs_outer_wrapper single_tab"
                // className="single_tab"
                // onSelect={handleTabClick}
                >
                  <Tab eventKey="setup" title="Setup">
                    <div className="campiagn_outer border-0 p-0">
                      <div className="cmn__filters campiagn_filter filter_menu without_filter_head">
                        <ul>
                          <li><img src="/assets/images/filter_list.svg" /></li>
                          <li>
                            <Dropdown className="owner_filter">
                              <Dropdown.Toggle id="dropdown-basic">
                                {tempInventory}
                                <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                  <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {inventoryId?.map((option, index) => {
                                  if ((option?.id == 1 || option?.id == 4))
                                    return (
                                      <>
                                        {
                                          <Dropdown.Item key={index} onClick={() => { setLineitem_searched_inventory(option?.id); setTempInventory(option?.description) }}>{option?.description}</Dropdown.Item>
                                        }
                                      </>
                                    )
                                })}

                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <Dropdown className="owner_filter">
                              <Dropdown.Toggle id="dropdown-basic">
                                {tempFormat}
                                <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                  <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {formatId?.map((option, index) => {
                                  if (option?.id == 14 || option?.id == 15 || option?.id == 16 || option?.id == 17) return null;
                                  return (
                                    <>
                                      <Dropdown.Item key={index} onClick={() => { setLineitem_searched_format(option?.id); setTempFormat(option?.name) }}>{option?.name}</Dropdown.Item>
                                    </>
                                  )

                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <Dropdown className="owner_filter">
                              <Dropdown.Toggle id="dropdown-basic">
                                {tempOffer}
                                <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                  <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {offerId?.map((option, index) => {
                                  if (option.id == 7 || option.id == 8 || option.id == 9) return null;
                                  return (
                                    <>
                                      <Dropdown.Item key={index} onClick={() => { setLineitem_searched_offer(option?.id); setTempOffer(option?.descripcion) }}>{option?.descripcion}</Dropdown.Item>
                                    </>
                                  )


                                })}

                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <Dropdown className="owner_filter">
                              <Dropdown.Toggle id="dropdown-basic">
                                {tempCountry}
                                <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                  <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {countryId?.map((option, index) => {
                                  return (
                                    <>
                                      <Dropdown.Item key={index} onClick={() => { setLineitem_searched_country(option?.id); setTempCountry(option?.labelKey) }}>{option?.labelKey}</Dropdown.Item>
                                    </>
                                  )
                                })}

                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <button className="cmn_search_btn" onClick={() => handle_searched_lineitem(null, null)}>Search</button>
                          <button className="clear__btn" onClick={() => {
                            setLineitem_searched_country(null);
                            setLineitem_searched_offer(null);
                            setLineitem_searched_format(null);
                            setLineitem_searched_inventory(null)
                            setLineitem_searched_id(null);
                            setLineitem_searched_name("")
                            setTempCountry("Country")
                            setTempFormat("Format")
                            setTempOffer("Offer")
                            setTempInventory("Inventory")
                            setSearched_value("")
                            get_all_lineitems()
                          }} >Clear</button>
                        </ul>
                      </div>
                      <div className="advert_tabel campign_index_table saas_mediaplan_table">

                        <Table
                          responsive
                        >
                          <thead>
                            <tr style={{ cursor: "pointer" }}>
                              <th onClick={() => handle_sort("id")}
                                className="text-start"
                              >
                                ID <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("name")}
                                className="text-start"
                              >
                                Line item Name <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("inventory_type_id")}
                                className="text-start"
                              >
                                Inventory Type <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("formatNavigation")}
                                className="text-start"
                              >
                                Format <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("inversion_offer_type_id")}
                                className="text-start"
                              >
                                Offer Type <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th
                                className="text-start"
                              >
                                Country
                              </th>
                              <th onClick={() => handle_sort("inversion_budget")}
                                className="text-start"
                              >
                                Budget ({currency ? currency : deal_currency}) <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("bid_kite")}
                                className="text-start"
                              >
                                Bid <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("estimate_impression")}
                                className="text-start"
                              >
                                Estimated impressions <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("date_from")}
                                className="text-start"
                              >
                                Start date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("date_to")}
                                className="text-start"
                              >
                                End date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th onClick={() => handle_sort("status_id")}
                                className="text-start"
                              >
                                Status <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                              </th>
                              <th style={{ opacity: "0" }}>pdf</th>
                            </tr>
                          </thead>
                          <tbody>

                            {all_lineitems?.length == 0 && <div className="no_data"><img className="nodata" src="/assets/images/notfound.svg" /></div>}
                            {
                              all_lineitems?.map((lineitems, index) => {
                                return (
                                  <tr key={index}>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.id}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.name}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.inventoryNavigation?.description}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.formatNavigation?.name}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.offerTypeNavigation?.descripcion}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                    >
                                      {
                                        lineitems?.countryList?.map((country, i) => {
                                          return (
                                            <span key={i}>{country?.country?.ab.toUpperCase()}{i != lineitems?.countryList?.length - 1 ? ',' : ''}&nbsp;</span>
                                          )
                                        })
                                      }
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.inversion_budget?.toLocaleString()}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}>
                                      {lineitems?.bid_kite ? lineitems?.bid_kite : "NA"}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {lineitems?.estimated_impressions?.toLocaleString()}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {<Moment format="DD/MM/YYYY">
                                        {lineitems?.date_from}
                                      </Moment>}
                                    </td>
                                    <td onClick={() => go_to_next(lineitems?.id, campaign_id)}
                                      className="text-start"
                                    >
                                      {<Moment format="DD/MM/YYYY">
                                        {lineitems?.date_to}
                                      </Moment>}
                                    </td>
                                    <td>
                                      <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                        <Dropdown.Toggle className={`filter_btn`}>
                                          {lineitems?.status_id == 1 && <img src="/assets/images/draft_FILL.svg" />}
                                          {lineitems?.status_id == 1 && <span>  Draft</span>}
                                          {lineitems?.status_id == 2 && <img src="/assets/images/check_circle.svg" />}
                                          {lineitems?.status_id == 2 && <span>  Active</span>}
                                          {lineitems?.status_id == 3 && <img src="/assets/images/pause_circle.svg" />}
                                          {lineitems?.status_id == 3 && <span>  Pause</span>}
                                          {lineitems?.status_id == 28 && <img src="/assets/images/cancel_FILL.svg" />}
                                          {lineitems?.status_id == 28 && <span>  Inactive</span>}
                                          <FaChevronRight className="ms-auto" />
                                        </Dropdown.Toggle>
                                        {Object.values(permission.Permissions).includes('editcampaignsaas') && lineitems?.status_id != 28 && <Dropdown.Menu>
                                          <Dropdown.Item onClick={() => handle_show_status_pop(lineitems?.id, 1, lineitems?.status_id)}>
                                            {/* <Dropdown.Item onClick={() => handle_update_lineitem_status(lineitems?.id, 1)}> */}
                                            <img src="/assets/images/draft_FILL.svg" />
                                            Draft
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={() => handle_show_status_pop(lineitems?.id, 2, lineitems?.status_id)}>
                                            <img src="/assets/images/check_circle.svg" />
                                            Active
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={() => handle_show_status_pop(lineitems?.id, 3, lineitems?.status_id)}>
                                            <img src="/assets/images/pause_circle.svg" />
                                            Pause
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={() => handle_show_status_pop(lineitems?.id, 28, lineitems?.status_id)}>
                                            <img src="/assets/images/cancel_FILL.svg" />
                                            Inactive
                                          </Dropdown.Item>
                                        </Dropdown.Menu>}
                                      </Dropdown>
                                    </td>
                                    <td className="text-start" >
                                      <div className="icons_downloads">
                                        <div className="pdf_icon" title="View Placement List"
                                          onClick={() =>
                                            navigate(
                                              `/campaignlist/${lineitems?.id}`
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img src="/assets/images/play_circle.svg" />
                                        </div>
                                        {(deal_status_id === 1 || deal_status_id === 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && (
                                          <div
                                            className="pdf_icon"
                                            title="Edit Lineitem"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => { handle_edit_lineitem(lineitems?.id); setLineitem_status(lineitems?.status_id) }}
                                          >
                                            <img src="/assets/images/edit_fill.svg" alt="Edit Lineitem" />
                                          </div>
                                        )}

                                        <div className="pdf_icon" title="View Creatives" style={{ cursor: "pointer" }}>
                                          <img src="/assets/images/description_file.svg" onClick={() => go_to_next(lineitems?.id, campaign_id)} />
                                        </div>
                                        <div className="pdf_icon" title="Change history" style={{ cursor: "pointer" }}>
                                          <img src="/assets/images/clock.svg" onClick={() => navigate("/lineItemshistory", { state: { id: lineitems?.id, pathname: location?.pathname, campaign_id: campaign_id, client_id: client_id, dealId, deal_status_id: deal_status_id, deal_currency } })} />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>

                        {all_lineitems?.length != 0 &&
                          <div className="cmn__pagination">
                            <ul>
                              <span>Rows per page</span>
                              <li className="no__rows">
                                <select
                                  name=""
                                  id=""
                                  onChange={(e) => setPageSize(e.target.value)}
                                >
                                  {/* <option value={10}>Select</option> */}
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={15}>15</option>
                                  <option value={20}>20</option>
                                </select>
                              </li>
                              <li className="total_pages me-3">
                                <span className="active_pages">{page}</span> of <span>{total_pages}</span>
                              </li>
                              {page !== 1 && <li>
                                <BsChevronBarLeft
                                  onClick={() => setPage(1)}
                                />
                              </li>}
                              {page !== 1 && <li>
                                <BsChevronLeft
                                  onClick={() => setPage(page - 1)}
                                />
                              </li>}
                              {page !== total_pages && <li
                              >
                                <BsChevronRight
                                  onClick={() => setPage(page + 1)}
                                />
                              </li>}
                              {page !== total_pages && <li>
                                <BsChevronBarRight
                                  onClick={() => setPage(total_pages)}
                                />
                              </li>}
                            </ul>
                          </div>

                        }
                      </div>
                    </div>
                  </Tab>
                  {Object.values(permission.Permissions).includes('viewOptimizeTab') &&
                    <Tab eventKey="profile" title="Optimize">
                      <div className="new_compaign tabs_wrappers">
                        <div className="cmn__filters campiagn_filter filter_menu without_filter_head">
                          <ul>
                            <li><img src="/assets/images/filter_list.svg" /></li>
                            <li>
                              <Dropdown className="owner_filter">
                                <Dropdown.Toggle id="dropdown-basic">
                                  {tempInventory}
                                  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                    <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {inventoryId?.map((option, index) => {
                                    if ((option?.id == 1 || option?.id == 4))
                                      return (
                                        <>
                                          {
                                            <Dropdown.Item key={index} onClick={() => { setLineitem_searched_inventory(option?.id); setTempInventory(option?.description) }}>{option?.description}</Dropdown.Item>
                                          }
                                        </>
                                      )
                                  })}

                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                            <li>
                              <Dropdown className="owner_filter">
                                <Dropdown.Toggle id="dropdown-basic">
                                  {tempFormat}
                                  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                    <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {formatId?.map((option, index) => {
                                    if (option?.id == 14 || option?.id == 15 || option?.id == 16 || option?.id == 17) return null;
                                    return (
                                      <>
                                        <Dropdown.Item key={index} onClick={() => { setLineitem_searched_format(option?.id); setTempFormat(option?.name) }}>{option?.name}</Dropdown.Item>
                                      </>
                                    )

                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                            <li>
                              <Dropdown className="owner_filter">
                                <Dropdown.Toggle id="dropdown-basic">
                                  {tempOffer}
                                  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                    <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {offerId?.map((option, index) => {
                                    if (option.id == 7 || option.id == 8 || option.id == 9) return null;
                                    return (
                                      <>
                                        <Dropdown.Item key={index} onClick={() => { setLineitem_searched_offer(option?.id); setTempOffer(option?.descripcion) }}>{option?.descripcion}</Dropdown.Item>
                                      </>
                                    )


                                  })}

                                </Dropdown.Menu>
                              </Dropdown>
                            </li>

                            <button className="cmn_search_btn" onClick={() => handle_searched_lineitem(null, null)}>Search</button>
                            <button className={(searched_value.length > 0 || lineitem_searched_country || lineitem_searched_offer || lineitem_searched_format || lineitem_searched_inventory) ? "clear__btn1" : "clear__btn"} onClick={() => {
                              setLineitem_searched_country(null);
                              setLineitem_searched_offer(null);
                              setLineitem_searched_format(null);
                              setLineitem_searched_inventory(null)
                              setLineitem_searched_id(null);
                              setLineitem_searched_name("")
                              setTempCountry("Country")
                              setTempFormat("Format")
                              setTempOffer("Offer")
                              setTempInventory("Inventory")
                              setSearched_value("")
                              get_all_lineitems()
                            }} >Clear</button>
                          </ul>
                        </div>
                        <div className="advert_tabel campaign_showlist campign_index_table">
                          <div className="campiagn_outer border-0 p-0">
                            <div className="advert_tabel campaign_showlist optimize_table ">
                              <div className="applyCenter mb-3">
                                {update_bulk &&
                                  <div className="divApplyChanges">
                                    `<button
                                      className="aplly_btn"
                                      disabled={validate_apply_button}
                                      onClick={() => (!validate_apply_button && handleUpdateOptimizeTab())}>
                                      Apply changes
                                    </button>
                                    <button
                                      className="clear__btn1"
                                      onClick={() => {
                                        setUpdate_bulk(false);
                                        get_filtered_optimize_data();
                                      }}
                                    >
                                      Cancel changes
                                    </button>
                                  </div>
                                }
                              </div>
                              <Table
                                responsive
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      Name(ID)
                                    </th>
                                    <th>LI Details</th>
                                    <th>Status</th>
                                    <th>Pacing Rate</th>
                                    <th>Budget</th>
                                    <th>Bid</th>
                                    <th>Spent to date</th>
                                    <th>Pacing to date</th>
                                    <th>Delivery Completion</th>
                                    <th>eCPM/eCPV {deal_currency}</th>
                                    <th style={{ cursor: "pointer" }}>Daily delivery
                                      <svg
                                        className="ms-2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        height="20"
                                        width="20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="12" y1="8" x2="12" y2="12"></line>
                                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                        <title>You can see each day's delivery by hovering over the bar for that day</title>
                                      </svg>
                                    </th>
                                    <th>Remaining Days</th>
                                    {Object.values(permission.Permissions).includes('editOptimizeTab') && <th></th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {mapData?.map((data, index) => {
                                    return (
                                      <tr key={index}>
                                        <td style={{ width: "252px" }} title={data?.id + " " + data?.name}>{data?.name}({data?.id})</td>
                                        <td>{data?.inventoryTypeName}<br /> {data?.formatName} <br /> {data?.offerTypeName} </td>
                                        <td>
                                          <div className="filter_menu ">
                                            <Dropdown className="advert">
                                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                                <span className="active__option font_bold me-2">
                                                  {data?.lineItemStatus == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                                  {data?.lineItemStatus == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                                  {data?.lineItemStatus == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                                  {data?.lineItemStatus == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                                  {data?.lineItemStatusName}
                                                </span>
                                                {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') && <FaChevronRight />}
                                              </Dropdown.Toggle>
                                              {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                                <Dropdown.Menu className="child_filters">
                                                  <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Draft", 1)}> <img className="me-2" src="/assets/images/draft_FILL.svg" />Draft</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Active", 2)}> <img className="me-2" src="/assets/images/check_circle.svg" />Active</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Pause", 3)}><img className="me-2" src="/assets/images/pause_circle.svg" />Pause</Dropdown.Item>
                                                  <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Inactive", 28)}> <img className="me-2" src="/assets/images/cancel_FILL.svg" />Inactive</Dropdown.Item>
                                                </Dropdown.Menu>
                                              )}
                                            </Dropdown>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="filter_menu ">
                                            <Dropdown className="advert">
                                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                                <span>
                                                  {data?.pacingRateName}
                                                </span>
                                                {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') && <FaChevronRight />}
                                              </Dropdown.Toggle>
                                              {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                                <Dropdown.Menu className="child_filters">
                                                  {pacing_rate?.data?.data?.map((val, i) => {
                                                    return (
                                                      <Dropdown.Item onClick={() => handleChangeValues(index, "pacingRateName", val?.description, val?.id)}>{val?.description}</Dropdown.Item>
                                                    )
                                                  })}
                                                </Dropdown.Menu>
                                              )}
                                            </Dropdown>
                                          </div>
                                        </td>
                                        {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') ? (
                                          <td
                                            onClick={() => {
                                              handleEditData("budget", index);
                                              setValidate_apply_button();
                                              handle_validate_budhet(index)
                                            }}>
                                            {data?.budgetTrue ?
                                              <input
                                                onBlur={(e) => {
                                                  handleChangeValues(index, "budget", e)
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder={data?.inversionBudget} /> :
                                              <div className="edit_wrapper d-flex gap-2 align-items-center">
                                                <div className="pdf_icon" title="Edit Line Item">
                                                  <img src="/assets/images/edit_fill.svg" />
                                                </div>
                                                <span title={data?.inversionBudget}>
                                                  {data?.inversionBudget}
                                                </span>
                                              </div>
                                            }
                                          </td>
                                        ) : (
                                          <td>
                                            <div className="edit_wrapper d-flex gap-2 align-items-center">
                                              <span title={data?.inversionBudget}>
                                                {data?.inversionBudget}
                                              </span>
                                            </div>
                                          </td>
                                        )
                                        }
                                        {data?.lineItemStatusName != 'INACTIVE' && Object.values(permission.Permissions).includes('editOptimizeTab') ? (
                                          <td
                                            onClick={() => {
                                              handleEditData("bid", index);
                                              handle_validate_bid(index);
                                            }}
                                          >
                                            {data?.bidTrue ?
                                              <input
                                                onBlur={(e) => {
                                                  handleChangeValues(index, "bid", e)
                                                }}
                                                type="text"
                                                className="form-control"
                                                placeholder={data?.bid}
                                              /> :
                                              <div className="edit_wrapper d-flex gap-2 align-items-center">
                                                <div className="pdf_icon" title="Edit Line Item" ><img src="/assets/images/edit_fill.svg" /></div>
                                                <span title={data?.bid}>{data?.bid}</span>
                                              </div>
                                            }
                                          </td>
                                        ) : (
                                          <td>
                                            <div className="edit_wrapper d-flex gap-2 align-items-center">
                                              <span title={data?.bid}>{data?.bid}</span>
                                            </div>
                                          </td>
                                        )
                                        }
                                        {/* this is the spent coming from fedrico endpoint*/}
                                        <td>
                                          {optimize_saas_report_data?.loading ?
                                            <li>
                                              Loading...
                                            </li> :
                                            optimize_saas_report_data?.isSuccess && (
                                              reportData?.map((val, i) => {
                                                if (val?.lineitemId === data?.id) {
                                                  return (
                                                    <span key={i}>
                                                      {val?.success ? "$" + val?.spent?.toLocaleString(undefined, { maximumFractionDigits: 2 }) : "Has not delivered"}
                                                    </span>
                                                  )
                                                }
                                              }))}
                                        </td>
                                        {/* <td><Moment format="DD/MM/YYYY">{data?.spenttodate}</Moment></td> */}
                                        <td>{optimize_saas_report_data?.loading ? <li>Loading..</li> : optimize_saas_report_data?.isSuccess && (reportData?.map((val, i) => {
                                          if (val?.lineitemId === data?.id) {
                                            return (
                                              <span>
                                                {
                                                  val?.success ?
                                                    (Math.abs((val?.spent / ((data?.inversionBudgetOriginal / (moment(campaign_details?.date_to).diff(moment(campaign_details?.date_from), "days") + 1)) * (moment(campaign_details?.date_from).diff(moment(), "days"))))) * 100)?.toFixed(2) + "%"

                                                    : "Has not delivered"
                                                }
                                              </span>
                                            )
                                          }
                                        }))}</td>

                                        <td>{optimize_saas_report_data?.loading ? <li>Loading..</li> : optimize_saas_report_data?.isSuccess && (reportData?.map((val, i) => {
                                          if (val?.lineitemId === data?.id) {
                                            return (
                                              <span key={i}>
                                                {
                                                  val?.success ? ((val?.spent / data?.inversionBudgetOriginal) * 100)?.toFixed(2) + "%" : "Has not delivered"
                                                }
                                                <span>
                                                  {

                                                  }
                                                </span>
                                              </span>
                                            )
                                          }
                                        }))}</td>
                                        <td>{optimize_saas_report_data?.loading ? <li>Loading..</li> : optimize_saas_report_data?.isSuccess && (reportData?.map((val, i) => {
                                          if (val?.lineitemId === data?.id) {
                                            return (
                                              <span key={i}>
                                                {
                                                  val?.success ? (val?.costPerUnitCurrencyDeal)?.toFixed(4) : "Has not delivered"
                                                }
                                                <span>
                                                  {

                                                  }
                                                </span>
                                              </span>
                                            )
                                          }
                                        }))}</td>
                                        <td>
                                          <ul className="days_weeks p-0">
                                            {optimize_saas_report_data?.loading ? (
                                              <li>Loading..</li>
                                            ) : optimize_saas_report_data?.isSuccess &&
                                            reportData?.map((val, i) => {
                                              if (val?.lineitemId === data?.id) {
                                                if (val?.success) {
                                                  const dates = val?.dates || [];
                                                  const lastDate = dates.length > 0 ? new Date(dates[dates.length - 1].date) : new Date();

                                                  const nextDates = dates.length < 7 ? Array.from({ length: 7 - dates.length }).map((_, index) => {
                                                    const date = new Date(lastDate);
                                                    date.setDate(date.getDate() + index + 1);
                                                    return date.toISOString().split('T')[0];
                                                  }) : [];

                                                  const allDates = [...dates.map(date => date.date), ...nextDates];

                                                  return allDates.map((date, index) => {
                                                    const spent = val?.dates[index]?.spent ?? 0;
                                                    return (
                                                      <li key={index}>
                                                        <span className="block_bg">
                                                          <span
                                                            title={"Spent " + spent.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            style={{ height: `${spent * 10}px` }} // Adjusted height calculation
                                                          ></span>
                                                        </span>
                                                        <span>
                                                          <Moment format="D">{date}</Moment>
                                                        </span>
                                                      </li>
                                                    );
                                                  });
                                                } else {
                                                  return (
                                                    <span>Has not delivered</span>
                                                  );
                                                }
                                              }
                                              return null;
                                            })}
                                          </ul>
                                        </td>
                                        <td>
                                          {data?.remainingDays}
                                        </td>
                                        {Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                          <td title="Edit all the properties of the lineitem"><div className="pdf_icon" onClick={() => { setLineitem_id(data?.id); handleShowLineitem(); setLineitem_status(data?.lineItemStatus) }}><img src="/assets/images/edit_fill.svg" /></div></td>
                                        )}
                                      </tr>
                                    )
                                  })}

                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  }
                </Tabs>



              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add new line item */}
      <Modal
        show={show} onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="contained-modal-title-vcenter">
            New Lineitem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <NewLineItemSaas
            handleClose={handleClose}
            get_all_lineitems={get_all_lineitems}
            campaign_id={campaign_id}
            startDate={campaign_current_start_date}
            endDate={campaign_current_end_date}
            campaign_budget={campaign_details?.budge}
            dealId={campaign_details?.dealID}
            get_selected_campaign_detail={get_selected_campaign_detail}
            deal_currency={currency ? currency : deal_currency}
            remaining_budget={(campaign_details?.budge) - (campaign_details?.lineItemTotalBudget)}
          />
        </Modal.Body>
      </Modal>

      {/* for edit lineitem from lineitem pencil icon */}
      <Modal
        show={showEditLineitem} onHide={handleCloseEditLineitem}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Lineitem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <EditCampaignSaas
            handleClose={handleCloseEditLineitem}
            get_all_lineitems={get_all_lineitems}
            campaign_id={campaign_id}
            startDate={campaign_current_start_date}
            endDate={campaign_current_end_date}
            campaign_budget={campaign_details?.budge}
            dealId={campaign_details?.dealID}
            lineitem_id={lineitem_id}
            deal_currency={currency ? currency : deal_currency}
            get_selected_campaign_detail={get_selected_campaign_detail}
            remaining_budget={(campaign_details?.budge) - (campaign_details?.lineItemTotalBudget)}
            lineitem_status={lineitem_status}
          />
        </Modal.Body>
      </Modal>

      {/* this below modal is for edit the campaigns nitin 06/09/2023 */}
      <Modal
        show={showCampaign} onHide={handleCloseCampaign}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="saas_modal_head">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <Row>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Campaign Name</label>
                <input className="form-control" type="text" placeholder="Company Name*"
                  value={campaign_name} onChange={(e) => setCampaign_name(e.target.value)}
                />

              </div>
            </Col>
            <Col lg={3}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Start date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  disabled={campaign_start_date <= new Date()}
                  value={campaign_start_date}
                  onChange={date => setCampaign_start_date(date)}
                  selected={campaign_start_date}
                />
              </div>
              <p>Actual Start date: {<Moment format="DD/MM/YYYY">
                {campaign_current_start_date}
              </Moment>} </p>
            </Col>
            <Col lg={3}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">End date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  value={campaign_end_date}
                  onChange={date => setCampaign_end_date(date)}
                  selected={campaign_end_date}
                />
              </div>
              <p>Actual end date: {<Moment format="DD/MM/YYYY">
                {campaign_current_end_date}
              </Moment>} </p>
            </Col>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">Budget({currency ? currency : deal_currency}) (Budget not lower than {campaign_details?.lineItemTotalBudget})</label>
                <input className="form-control" type="text" placeholder="Budget"
                  value={campaign_budget} onChange={(e) => { setCampaign_budget(e.target.value) }}
                  onBlur={() => handle_get_lineitem_bid_usd_budget_usd()}
                />

              </div>
            </Col>
            <Col lg={6}>
              Budget USD : {campaign_budget_usd?.toFixed(2)}
            </Col>

          </Row>
          <div className="model_btn model_btn_new_style">
            <div> {campaign_details?.status_id == 1 && <button className="cancel_cm_btn  delete_cmn_btn delete_pink_btn" onClick={() => handleDeleteCampaign()}>Delete</button>}</div>
            <div>   <button
              className="cancel_cm_btn"
              onClick={handleCloseCampaign}
            >
              Cancel
            </button>
              <button className={active_button ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"}
                //this click function is used to save the new campaign nitin 05/09/2023
                onClick={() => handle_update_campaigns()}
              >
                Update
              </button></div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default LineItemsSaas;